import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const LesCours = () => (
  <Layout>
     <SEO title="Les cours" description="Cours de danse contemporaine et entrainement régulier du danseur (E.R.D.D) sont proposées par la Compagnie Ailes de Cire sur St-Orens et Toulouse." />
    <section id="les-cours-banner">
      <h1>LES COURS</h1>
    </section>
    <section className="main container">

    <h2>PLANNING DES COURS</h2>
    
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.047" height="139.391" viewBox="0 0 1921.047 139.391">
      <path id="Tracé_11" data-name="Tracé 11" d="M7960,1227.819c915.685,44.744,1394.665,78.159,1645.546,100.923,275.473,25,274.453,37.077,274.453,37.077" transform="translate(-7959.952 -1226.821)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">

    <p><strong>Au sein de l’association <a href="https://choreacorps.fr/" target="_blank" rel="noreferrer" >CHORE A CORPS</a></strong></p>
    <h3>À COLOMIERS ou ST MARTIN DU TOUCH</h3>
    <p><i>STUDIO ACCOR'DANCE ET CHORE A CORPS, 11 CHEM. DE BORDEBLANQUE BAT A5, 31770 COLOMIERS.</i></p>
    <p><u>SAMEDI :</u></p>
    <ul>
        <li>11h20 - 12h20 (une semaine sur deux) : Atelier Parents Enfants</li>
    </ul>
    <p><strong>Atelier Parents-Enfants de 2 à 4 ans.</strong></p>
    <p>C'est la possibilité de passer un moment privilégié avec votre enfant et de réapprendre à se découvrir. 
    Le studio devient un lieu de partage et de convivialité. 
    L'objectif est d’accompagner l’enfant à devenir autonome, et d'éveiller ses sens au fil des propositions chorégraphiques et sensorielles.
    Tous vos sens seront mis en éveil, de manière ludique, mélodique et poétique.
    Une grande place sera accordée à l’imaginaire et au travail de visualisation et de sensation fine.
    Et chaque séance sera axée sur une thématique spécifique.
    L’atelier pourra donner lieu à un spectacle si les participants le souhaitent.</p>
    <p><strong>Atelier Parents-Enfants de 5 à 17 ans.</strong></p>
    <p>L'objectif de cet atelier est de passer un moment de partage et de danse avec votre enfant et de partager les expériences corporelles de chacun en fonction de son état dans l’instant présent.
    Danser et partager pour cultiver la confiance et renforcer la relation qui unit les corps.
    La richesse de l’atelier réside dans le partage d’expérience.
    Il est donc possible de venir avec son papa, sa mamie, sa tata, etc... Si vous le souhaitez !</p>
    <p>Musique, Poésie et Arts plastiques viendront pimenter cet atelier et il sera possible en fin d’année de présenter un spectacle selon la motivation du groupe.
    Chaque séance sera axée sur une thématique spécifique.</p>
    <p><strong>Plus d’infos sur les tarifs et le planning complet de l’école sur <a href="https://choreacorps.fr/studio-colomiers/" target="_blank" rel="noreferrer">choreacorps.fr</a></strong></p>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.046" height="136.58" viewBox="0 0 1921.046 136.58">
        <path id="Tracé_10" data-name="Tracé 10" d="M7960,1363c915.685-43.829,1394.665-76.561,1645.546-98.86C9881.02,1239.654,9880,1227.819,9880,1227.819" transform="translate(-7959.953 -1227.416)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
  </Layout>
)

export default LesCours
